import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import Truncate from 'react-truncate';
import StoryChief from '@/storychief';
import MediaItem from '@/media/components/MediaItem';

const propTypes = {
  entry: PropTypes.shape({
    meta_title: PropTypes.string,
    meta_description: PropTypes.string,
    url: PropTypes.string,
    og_image: PropTypes.string,
    og_description: PropTypes.string,
    loading: PropTypes.bool,
  }).isRequired,
};

const defaultProps = {};

const fragments = {
  link: gql`
    fragment LinkTeaserListItem on Link {
      __typename
      id
      url
      meta_title
      meta_description
      og_image
      og_description
    }
  `,
};

function LinkTeaserListItem({ entry: link }) {
  const dummyLink = {
    og_image: StoryChief.asset('images/placeholders/featured-image.png'),
    meta_title: 'Undefined title',
    meta_description: 'Undefined description',
  };

  if (!link) return <div>loading...</div>;
  return (
    <div className="link-teaser--list-item d-flex flex-row">
      <div className="gutter-right-3">
        <MediaItem
          object={{ url: link.og_image || dummyLink.og_image, mime_type: 'image/*' }}
          backgroundImage
          className="image--thumb"
          size="thumb"
        />
      </div>
      <div className="d-flex flex-column flex-grow">
        {link.loading ? (
          <div className="col-sm-12">
            <div className="d-flex flex-column">
              <div className="placeholder-animated-2">
                <div className="ctrl" />
                <div className="ctrl ctrl-80" />
              </div>
            </div>
          </div>
        ) : (
          <div className="white-space-normal small">
            <div className="strong">
              <Truncate lines={1}>{link.meta_title || dummyLink.meta_title}</Truncate>
            </div>
            <div className="text-info">
              <Truncate lines={1}>{link.url}</Truncate>
            </div>
            <div className="d-flex text-muted">
              <Truncate lines={1}>
                {link.meta_description || link.og_description || dummyLink.meta_description}
              </Truncate>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

LinkTeaserListItem.propTypes = propTypes;
LinkTeaserListItem.defaultProps = defaultProps;
LinkTeaserListItem.fragments = fragments;

export default LinkTeaserListItem;
