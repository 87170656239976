import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation, useQuery } from '@apollo/client';
import Sidebar from '@/storychief/components/Sidebar';
import Datatable from '@/datatables/components/Datatable';
import buildPostsetEditAttachmentLinkDatatableDefinition from '@/postsets/components/datatables/definitions/buildPostsetEditAttachmentLinkDatatableDefinition';
import SidebarSubHeaderInputButton from '@/storychief/components/SidebarSubHeaderInputButton';
import DatatableContainer from '@/datatables/components/DatatableContainer';
import Pager from '@/datatables/components/Pager';
import appendUrlProtocol from '@/storychief/utils/appendUrlProtocol';
import useSidebars from '@/sidebars/hooks/useSidebars';
import DatatableProvider from '@/datatables/components/DatatableProvider';
import { PostsetMiddleware, ErrorsMiddleware } from '@/graphql/middlewares';

const propTypes = {
  postset: PropTypes.shape().isRequired,
};

const defaultProps = {};

const fragments = {
  link: gql`
    fragment PostsetEditAttachmentLink on Link {
      __typename
      id
      account_id
      url
      meta_title
      meta_description
      og_title
      og_description
      og_image
      twitter_title
      twitter_description
      twitter_image
      deleted_at
      created_at
      updated_at
    }
  `,
};

const ATTACH_POSTSET_LINK_MUTATION = gql`
  mutation PostsetEditAttachmentLinkAttachPostsetLink($id: ID!, $link_id: ID!) {
    attachPostsetLink(id: $id, link_id: $link_id) {
      id
      tailored_link {
        __typename
        image
        title
        description
      }
      posts {
        __typename
        id
        tailored_story {
          image
          title
          description
          method
        }
        tailored_images {
          attachable_id
          image
          image_edits
        }
        tailored_link {
          image
          title
          description
          method
        }
      }
      attachments {
        __typename
        caption
        attachable {
          ... on Link {
            __typename
            id
            og_image
            meta_title
            meta_description
            url
            deleted_at
          }
        }
      }
    }
  }
`;

export const CREATE_POSTSET_EDIT_ATTACHMENT_LINK_MUTATION = gql`
  mutation CreateLinkAttachment($input: CreateLinkInput!) {
    createLink(input: $input) {
      ...PostsetEditAttachmentLink
    }
  }
  ${fragments.link}
`;

const POSTSET_EDIT_ATTACHMENT_LINK_QUERY = gql`
  query PostsetEditAttachmentLinkQuery($first: Int!, $page: Int, $orderBy: [OrderByClause!]) {
    links(first: $first, page: $page, orderBy: $orderBy) {
      __typename
      data {
        ...PostsetEditAttachmentLink
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
  ${fragments.link}
`;

function PostsetEditAttachmentLink({ postset }) {
  // Queries
  const {
    data: { links } = {},
    variables,
    refetch,
    loading,
  } = useQuery(POSTSET_EDIT_ATTACHMENT_LINK_QUERY, {
    variables: {
      first: 25,
      page: 1,
      orderBy: [
        {
          column: 'created_at',
          order: 'DESC',
        },
      ],
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  // Cache updates
  function updateCache(cache, result) {
    const _query = cache.readQuery({
      query: POSTSET_EDIT_ATTACHMENT_LINK_QUERY,
      variables,
    });

    cache.writeQuery({
      query: POSTSET_EDIT_ATTACHMENT_LINK_QUERY,
      variables,
      data: {
        links: {
          __typename: _query.links.__typename,
          data: [result.data.createLink, ..._query.links.data],
          paginatorInfo: _query.links.paginatorInfo,
        },
      },
    });
  }

  // Mutations
  const [attachPostsetLink] = useMutation(ATTACH_POSTSET_LINK_MUTATION, {
    context: { middlewares: [new PostsetMiddleware(postset.id), new ErrorsMiddleware()] },
  });
  const [createLink, { loading: loadingLinkMutation }] = useMutation(
    CREATE_POSTSET_EDIT_ATTACHMENT_LINK_MUTATION,
    {
      update: updateCache,
      onCompleted: handleOnCompleted,
      onError: handleOnError,
    },
  );

  // States
  const [linksData, setLinksData] = useState(links ? links.data : []); // internal state of links
  const [isError, setIsError] = useState(false);
  const [inputValue, setInputValue] = useState('');

  // Hooks
  const sidebars = useSidebars();

  // Effects
  useEffect(() => {
    if (links) {
      setLinksData(links.data);
    }
  }, [links]); // Every time links change from query, update the internal state linksData

  // Functions
  function handleOnInputSubmit(url) {
    // add a dummy loading link into links, for knowing when to show the loading animations
    const _linkData = {
      id: 'placeholder',
      loading: true,
    };
    setLinksData([_linkData, ...linksData]);
    setIsError(false);

    createLink({
      variables: {
        input: {
          url: appendUrlProtocol(url),
        },
      },
    });
  }

  function handleOnError() {
    setLinksData(linksData.slice(1, linksData.length));
    setIsError(true);
  }

  function handleOnCompleted() {
    setInputValue('');
  }

  function handleOnPageChange(page) {
    refetch({ ...variables, page });
  }

  function onLinkListItemClick(linkId) {
    // close the sidebar immediately
    sidebars.toggle('PostsetEditAttachmentLink');

    // and start updating the postset
    attachPostsetLink({
      variables: {
        id: postset.id,
        link_id: linkId,
      },
    });
  }

  // Variables
  const currentPage = links && links.paginatorInfo.currentPage;

  // Rendering
  return (
    <>
      <Sidebar.Header title="Choose link" sidebarId="PostsetEditAttachmentLink" />

      <Sidebar.SubHeader>
        <div className="sidebar-sub-header-callin-grey">
          <SidebarSubHeaderInputButton
            onSubmit={(url) => handleOnInputSubmit(url)}
            isLoading={loadingLinkMutation}
            placeholder="Enter URL..."
            label="Add new link"
            inputValue={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            isError={isError}
            errorMessage="An error occurred while fetching the link. Make sure the URL is properly formatted and accessible to anyone without authentication."
          />
        </div>
      </Sidebar.SubHeader>

      <Sidebar.Content className="postset-edit-attachment-link-sidebar">
        <div className="d-flex flex-column h-100">
          <DatatableProvider
            name="postset-edit-attachment-link"
            buildFunction={buildPostsetEditAttachmentLinkDatatableDefinition}
            buildFunctionData={{ onLinkListItemClick }}
          >
            <DatatableContainer loading={loading} entries={linksData || []}>
              <Datatable
                className="datatable--hover"
                showHeaders={false}
                loading={loading}
                entries={linksData || []}
              />
            </DatatableContainer>
          </DatatableProvider>
          {links && links.paginatorInfo && (
            <Pager
              variant="datatable"
              current_page={currentPage}
              total_pages={links.paginatorInfo.lastPage}
              total={links.paginatorInfo.total}
              per_page={links.paginatorInfo.perPage}
              onPageChanged={handleOnPageChange}
            />
          )}
        </div>
      </Sidebar.Content>
    </>
  );
}

PostsetEditAttachmentLink.propTypes = propTypes;
PostsetEditAttachmentLink.defaultProps = defaultProps;
PostsetEditAttachmentLink.fragments = fragments;

export default PostsetEditAttachmentLink;
