import LinkTeaserListItem from '@/links/components/LinkTeaserListItem';

export default function ({ onLinkListItemClick }) {
  return [
    {
      key: 'title',
      title: 'Link',
      sortKey: 'title',
      icon: 'fa:align-left',
      description: 'The details of the link.',
      component: LinkTeaserListItem,
      handleOnClick: onLinkListItemClick,
      visible: true,
      fontSize: 'regular',
    },
  ];
}
