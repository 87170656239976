import PropTypes from 'prop-types';
import { Form, Button, FormGroup, InputGroup, FormControl, HelpBlock } from 'react-bootstrap';

const propTypes = {
  inputValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  isError: PropTypes.bool,
};

const defaultProps = {
  placeholder: '',
  errorMessage: '',
  isError: false,
};

function SidebarSubHeaderInputButton({
  inputValue,
  onSubmit,
  onChange,
  isLoading,
  placeholder,
  label,
  isError,
  errorMessage,
}) {
  // Functions
  function handleSubmit(event) {
    if (event) event.preventDefault();
    if (!inputValue) return;
    onSubmit(inputValue);
  }

  // Rendering
  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup
        controlId="mutation"
        bsSize="small"
        className="m-0"
        validationState={isError ? 'error' : null}
      >
        <InputGroup>
          <FormControl
            type="text"
            value={inputValue}
            name="mutation"
            disabled={!!isLoading}
            placeholder={placeholder}
            onChange={onChange}
          />
          <InputGroup.Button>
            <Button
              type="button"
              disabled={!!isLoading}
              onClick={handleSubmit}
              className="btn btn-primary"
            >
              {label}
            </Button>
          </InputGroup.Button>
        </InputGroup>
        {isError && errorMessage && <HelpBlock>{errorMessage}</HelpBlock>}
      </FormGroup>
    </Form>
  );
}

SidebarSubHeaderInputButton.propTypes = propTypes;
SidebarSubHeaderInputButton.defaultProps = defaultProps;

export default SidebarSubHeaderInputButton;
